"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.deleteCollection = exports.updateCollectionItem = exports.createCollection = exports.queryCollection = exports.getCollection = exports.error = exports.reset = exports.remove = exports.fetch = exports.save = exports.update = exports.COLLECTIONS = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _omit2 = _interopRequireDefault(require("lodash/omit"));

var _mapKeys2 = _interopRequireDefault(require("lodash/mapKeys"));

var _groupBy2 = _interopRequireDefault(require("lodash/groupBy"));

var _utils = require("../../utils/utils");

var _api = _interopRequireDefault(require("../../utils/api"));

const COLLECTIONS = {
  DIVISION: 'divisions',
  DIVISION_BEHAVIORS: 'division_behaviors',
  DIVISION_FLIPGIVE_WIDGETS: 'division_flipgive_widgets',
  DIVISION_STRUCTURE_HEALTH: 'division_structure_health',
  HEALTH_CHECK_QUESTIONNAIRE: 'health_check_questionnaire',
  HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE: 'health_check_questionnaire_template',
  HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION: 'health_check_questionnaire_templates_question',
  INVOICING_HEALTH: 'invoicing_health',
  MEMBER: 'member',
  MEMBER_REGISTRATION_SIGNUPS: 'member_registration_signups',
  PROGRAM_MEMBERS: 'program_members',
  PROGRAM_MEMBERSHIPS: 'program_memberships',
  PROGRAM_MEMBER_PHOTOS: 'program_member_photos',
  REGISTRATION_DATE_AGGREGATE: 'registration_date_aggregate',
  REGISTRATION_FORM: 'registration_form',
  REGISTRATION_HEALTH: 'registration_health',
  TEAM_NAMES: 'team_names'
};
exports.COLLECTIONS = COLLECTIONS;
const snapiSlice = (0, _toolkit.createSlice)({
  name: 'snapi',
  initialState: Object.values(COLLECTIONS).reduce((acc, cur) => ({ ...acc,
    [cur]: {
      isFetching: false,
      isError: false,
      status: 'none'
    }
  }), {}),
  reducers: {
    fetch(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = true;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'loading';
    },

    save(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = { ...state[action.payload.key].items,
        ...action.payload.items
      };
    },

    update(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = action.payload.items;
    },

    remove(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = false;
      state[action.payload.key].status = 'success';
      state[action.payload.key].items = (0, _omit2.default)(state[action.payload.key].items, action.payload.item);
    },

    reset(state, action) {
      state[action.payload.key] = {};
    },

    error(state, action) {
      state[action.payload.key] = state[action.payload.key] || {};
      state[action.payload.key].isFetching = false;
      state[action.payload.key].isError = true;
      state[action.payload.key].status = 'error';
    }

  }
});
const {
  update,
  save,
  fetch,
  remove,
  reset,
  error
} = snapiSlice.actions;
exports.error = error;
exports.reset = reset;
exports.remove = remove;
exports.fetch = fetch;
exports.save = save;
exports.update = update;

const getCollection = (actionName, url, params, options = {}) => (dispatch, getState) => {
  const {
    replaceState,
    selectorCallback,
    fullResponse
  } = options; // Check if selector is passed in and check state before fetching action (should this check be elsewhere?).

  if (selectorCallback) {
    const collectionState = selectorCallback(getState());
    const isArray = Array.isArray(collectionState); // Check if array is not empty or value is 'truthy' and return selector items

    if (isArray && collectionState.length || !isArray && collectionState) {
      return Promise.resolve(collectionState);
    }
  } // Collection items did not exist in state, fetch from API


  dispatch(fetch({
    key: actionName
  }));
  return _api.default.get(url, params).then(response => {
    const mappedKeys = (0, _mapKeys2.default)(response.items, 'id');
    dispatch(replaceState ? update({
      key: actionName,
      items: mappedKeys
    }) : save({
      key: actionName,
      items: mappedKeys
    }));
    return Promise.resolve(fullResponse ? response : response.items);
  }).catch(error => {
    dispatch(error({
      key: actionName
    }));
    return Promise.reject(error);
  });
};
/**
 * queryCollection expects both actionNames and params to be objects
 * The actionName keys will be used as the query 'type' in the request, it will also format
 * these items as camelCase for parsed collectionJSON response.
 *
 * For example:
 *  queryCollection({ BATCH_INVOICE, INVOICE }, { batchInvoice__id: 1, invoice__batchInvoiceId: 1})
 */


exports.getCollection = getCollection;

const queryCollection = (actionNames, params) => dispatch => {
  const actions = Object.keys(actionNames).map(action => {
    const name = actionNames[action];
    return {
      name: action,
      requestType: (0, _utils.plissken)(name, true),
      responseType: (0, _utils.plissken)(name)
    };
  }); // Set fetching state for each query item.

  actions.map(action => dispatch(fetch({
    key: action.name
  }))); // Stringify 'types' for query endpoint.

  const types = actions.map(action => action.requestType).join(','); // Make API 'query' request and dispatch collections

  return _api.default.get('q', {
    types,
    ...params
  }).then(response => {
    const groupItems = (0, _groupBy2.default)(response.items, item => (0, _utils.plissken)(item.type));
    actions.map(action => dispatch(save({
      key: action.name,
      items: (0, _mapKeys2.default)(groupItems[action.responseType], 'id')
    })));
    return Promise.resolve(groupItems);
  }).catch(error => {
    actions.map(action => dispatch(error({
      key: action.name
    })));
    return Promise.reject(error);
  });
}; // Create Collection by name, url and params


exports.queryCollection = queryCollection;

const createCollection = (actionName, url, params, command = false) => dispatch => {
  dispatch(fetch({
    key: actionName
  }));
  const apiType = command ? 'cmd' : 'post';
  return _api.default[apiType](url, params, command).then(response => {
    dispatch(save({
      key: actionName,
      items: (0, _mapKeys2.default)(response.items, 'id')
    }));
    return Promise.resolve(response.items);
  }).catch(error => {
    dispatch(error({
      key: actionName
    }));
    return Promise.reject(error);
  });
};

exports.createCollection = createCollection;

const updateCollectionItem = (actionName, item) => ({
  type: `UPDATE_ITEM_${actionName}`,
  payload: item
});

exports.updateCollectionItem = updateCollectionItem;

const deleteCollection = (actionName, url, item) => dispatch => _api.default.delete(`${url}/${item.id}`).then(_response => {
  dispatch(remove({
    key: actionName,
    item
  }));
  return Promise.resolve();
}).catch(error => {
  dispatch(error({
    key: actionName
  }));
  return Promise.reject(error);
});

exports.deleteCollection = deleteCollection;
var _default = snapiSlice;
exports.default = _default;