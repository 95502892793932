"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR = exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR = exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION = exports.HEALTH_CHECK_QUESTIONNAIRE = void 0;
// ------------------------------------
// Constants
// ------------------------------------
const HEALTH_CHECK_QUESTIONNAIRE = 'healthCheck.HEALTH_CHECK_QUESTIONNAIRE';
exports.HEALTH_CHECK_QUESTIONNAIRE = HEALTH_CHECK_QUESTIONNAIRE;
const CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION = 'CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION';
exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION = CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION;
const CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR = 'CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR';
exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR = CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_ERROR;
const CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR = 'CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR';
exports.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR = CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION_ERROR;