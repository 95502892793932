"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _actions = require("../state/locationChange/actions");

var _appcues = require("../utils/appcues");

const appcuesMiddleware = () => next => action => {
  if ((action === null || action === void 0 ? void 0 : action.type) === _actions.LOCATION_CHANGE) {
    (0, _appcues.startAppcues)();
  }

  return next(action);
};

var _default = appcuesMiddleware;
exports.default = _default;