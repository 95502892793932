"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _parseInt2 = _interopRequireDefault(require("lodash/parseInt"));

var _initialState = _interopRequireDefault(require("../initialState"));

var _teamsnapSdk = _interopRequireDefault(require("../../utils/teamsnapSdk"));

function getById(state, pluralItemType) {
  if (state[pluralItemType] && state[pluralItemType].byId) {
    return { ...state[pluralItemType].byId
    };
  }

  return {};
}

const teamsnapSdkReducer = (state = _initialState.default.teamsnap, action) => {
  if (action.itemType) {
    const pluralItemType = (0, _teamsnapSdk.default)().getPluralType(action.itemType);
    const byId = getById(state, pluralItemType);

    if (/teamsnap.ADD_ITEMS_/g.exec(action.type)) {
      action.items.forEach(item => {
        byId[item.id] = item;
      });
      return { ...state,
        [pluralItemType]: {
          byId
        }
      };
    }

    if (/teamsnap.UPDATE_ITEM_/g.exec(action.type)) {
      return { ...state,
        [pluralItemType]: {
          byId: { ...state[pluralItemType].byId,
            [action.item.id]: action.item
          }
        }
      };
    }

    if (/teamsnap.REMOVE_ITEM_/g.exec(action.type)) {
      const newById = { ...byId
      };
      delete newById[(0, _parseInt2.default)(action.item.id)];
      return { ...state,
        [pluralItemType]: {
          byId: newById
        }
      };
    }

    return state;
  }

  return state;
};

var _default = teamsnapSdkReducer;
exports.default = _default;