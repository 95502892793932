"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initialState = _interopRequireDefault(require("../initialState"));

var _actions = require("../locationChange/actions");

var constants = _interopRequireWildcard(require("./constants"));

// ------------------------------------
// Reducer
// ------------------------------------
const leagueMessagesReducer = (state = _initialState.default.leagueMessages, action) => {
  switch (action.type) {
    case constants.SET_BROADCAST_EMAIL:
      return { ...state,
        broadcastEmail: action.broadcastEmail,
        attachments: action.attachments
      };

    case constants.CLEAR_BROADCAST_EMAIL:
      return { ...state,
        broadcastEmail: null,
        attachments: []
      };

    case constants.SET_SUBJECT:
      return { ...state,
        broadcastEmail: { ...state.broadcastEmail,
          subject: action.subject
        }
      };

    case constants.SET_BODY:
      return { ...state,
        broadcastEmail: { ...state.broadcastEmail,
          body: action.body
        }
      };

    case constants.SET_RECIPIENT:
      return { ...state,
        broadcastEmail: { ...state.broadcastEmail,
          [action.recipientType]: action.recipientValue
        }
      };

    case constants.SET_FROM_EMAIL_ADDRESS:
      return { ...state,
        broadcastEmail: { ...state.broadcastEmail,
          fromEmailAddress: action.fromEmailAddress
        }
      };

    case constants.SAVING_EMAIL_STATUS:
      return { ...state,
        savingEmailMessage: action.status,
        savingEmail: !!action.status
      };

    case constants.CLEAR_ATTACHMENTS:
      return { ...state,
        attachments: []
      };

    case constants.ADD_ATTACHMENT:
      return { ...state,
        attachments: [...state.attachments, action.attachment]
      };

    case constants.UPDATE_ATTACHMENT:
      return { ...state,
        attachments: state.attachments.map(attachment => action.attachment.id === attachment.id ? action.attachment : attachment)
      };

    case constants.UPDATE_ATTACHMENT_PROGRESS:
      {
        const newAttachments = state.attachments.map(attachment => {
          if (action.id === attachment.id) {
            return { ...attachment,
              progress: action.progress
            };
          }

          return attachment;
        });
        return { ...state,
          attachments: newAttachments
        };
      }

    case constants.DELETE_ATTACHMENT:
      return { ...state,
        attachments: state.attachments.filter(attachment => attachment !== action.attachment)
      };

    case constants.CLICK_DELETE_EMAIL:
      return { ...state,
        listEmails: { ...state.listEmails,
          deleteEmailIds: { ...state.listEmails.deleteEmailIds,
            [action.emailId]: action.checked
          },
          deleteAllEmails: false
        }
      };

    case _actions.LOCATION_CHANGE:
      // if we have a location change then we no longer want
      // to have all the emails checked to be deleted
      if (state.listEmails.deleteAllEmails) {
        return { ...state,
          listEmails: { ...state.listEmails,
            deleteEmailIds: {},
            deleteAllEmails: false
          }
        };
      }

      return state;

    case constants.CLICK_DELETE_ALL_EMAILS:
      if (action.checked !== state.listEmails.deleteAllEmails) {
        const deleteEmailIds = {};
        const deleteAllEmails = !!action.checked;

        if (deleteAllEmails) {
          if (action.broadcastEmailIds && action.broadcastEmailIds.length > 0) {
            action.broadcastEmailIds.forEach(id => {
              deleteEmailIds[id] = true;
            });
          }
        }

        return { ...state,
          listEmails: { ...state.listEmails,
            deleteEmailIds,
            deleteAllEmails
          }
        };
      }

      return state;

    default:
      return state;
  }
};

var _default = leagueMessagesReducer;
exports.default = _default;