"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _reduxThunk = _interopRequireDefault(require("redux-thunk"));

var _googleTagManager = _interopRequireDefault(require("../middleware/googleTagManager"));

var _appcues = _interopRequireDefault(require("../middleware/appcues"));

var _exceptionNotifier = _interopRequireWildcard(require("../middleware/exceptionNotifier"));

var _reduxLogger = require("redux-logger");

var _reducers = _interopRequireDefault(require("./reducers"));

var _default = (initialState = {}, history = {}) => {
  // ======================================================
  // Redux Logger Configuration
  // ======================================================
  const reduxLogger = (0, _reduxLogger.createLogger)({
    collapsed: true,
    timestamp: false
  }); // ======================================================
  // Middleware Configuration
  // ======================================================

  const middleware = [_reduxThunk.default, _googleTagManager.default, _appcues.default];

  if (process.env.NODE_ENV === 'production') {
    (0, _exceptionNotifier.setupNotifier)();
    middleware.push(_exceptionNotifier.default);
  } // ======================================================
  // Store Enhancers
  // ======================================================


  const enhancers = [];

  if (window.__DEBUG__) {
    middleware.push(reduxLogger);
  } // ======================================================
  // Store Instantiation and HMR Setup
  // ======================================================


  const store = (0, _toolkit.configureStore)({
    reducer: (0, _reducers.default)(),
    preloadedState: initialState,
    middleware
  });
  store.asyncReducers = {};

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const reducers = require("./reducers").default;

      store.replaceReducer(reducers(store.asyncReducers));
    });
  }

  return store;
};

exports.default = _default;