"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initialState = _interopRequireDefault(require("../initialState"));

var constants = _interopRequireWildcard(require("./constants"));

const leagueMembersReducer = (state = _initialState.default.leagueMembers, action) => {
  switch (action.type) {
    case constants.LIST_VIEW_SET_CURRENT_PAGE:
      return { ...state,
        listView: { ...state.listView,
          currentPage: action.payload.currentPage
        }
      };

    case constants.LIST_VIEW_SET_TOTAL_PAGES:
      return { ...state,
        listView: { ...state.listView,
          totalPages: action.payload.totalPages
        }
      };

    case constants.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT:
      return { ...state,
        listView: { ...state.listView,
          totalProgramMemberCount: action.payload.totalProgramMemberCount
        }
      };

    case constants.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE:
      return { ...state,
        listView: { ...state.listView,
          programMemberIdsPerPage: { ...state.listView.programMemberIdsPerPage,
            [action.payload.currentPage]: action.payload.programMemberIds
          }
        }
      };

    case constants.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE:
      return { ...state,
        listView: { ...state.listView,
          programMemberIdsPerPage: {
            1: null
          }
        }
      };

    case constants.LIST_VIEW_SEARCH_FILTERS:
      return { ...state,
        listView: { ...state.listView,
          searchFilterParams: action.payload.searchFilters
        }
      };

    default:
      return state;
  }
};

var _default = leagueMembersReducer;
exports.default = _default;