"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.notify = exports.setupNotifier = void 0;

var Sentry = _interopRequireWildcard(require("@sentry/browser"));

var _reduxSentryMiddleware = _interopRequireDefault(require("redux-sentry-middleware"));

var _notifierConfig = require("../utils/notifierConfig");

// https://forum.sentry.io/t/switching-to-sentry-javascript/4732
const isProduction = process.env.NODE_ENV === 'production'; // Setup & Initialize Exception Handler

const setupNotifier = (config = {}) => {
  Sentry.init({ ..._notifierConfig.notifyConfig,
    ...config
  });
}; // Manually throw exception error
// Sentry will also 'catch' any thrown errors -> `throw new Error('testy')`


exports.setupNotifier = setupNotifier;

const notify = (section, err, extra) => {
  const error = new Error(`${section} - ${err}`);

  if (isProduction) {
    Sentry.captureException(error, {
      extra: {
        data: extra
      }
    });
  }

  console.error('Exception Notifier: ', err, 'Extras: ', extra);
};

exports.notify = notify;

var _default = (0, _reduxSentryMiddleware.default)(Sentry);

exports.default = _default;