import React from 'react';
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect, Link } from 'react-router-dom';
import MemberPage from 'organization-frontend/dist/src/components/pages/member-view';
import MembersPage from 'organization-frontend/dist/src/components/pages/members';
import AddMemberPage from 'organization-frontend/dist/src/components/pages/add-member';
import ProgramsPage from 'organization-frontend/dist/src/components/pages/programs';
import ProgramNew from 'organization-frontend/dist/src/components/pages/new-program';
import ProgramEdit from 'organization-frontend/dist/src/components/pages/edit-program';
import SeasonsPage from 'organization-frontend/dist/src/components/pages/seasons';
import DashboardPage from 'organization-frontend/dist/src/components/pages/dashboard';
import PageNotFound from 'organization-frontend/dist/src/components/pages/page-not-found';
import EmployeeOrganizations from 'organization-frontend/dist/src/components/pages/employee/organizations';
import ProgramReportPage from 'organization-frontend/dist/src/components/pages/program-report';
import OrgSettingsPage from 'organization-frontend/dist/src/components/pages/organization-settings';
import StaffPage from 'organization-frontend/dist/src/components/pages/staff';
import OrgNav from 'organization-frontend/dist/src/hub/hub-nav';
import OrgConfigurator from 'organization-frontend/dist/src/hub/app-configurator';

import { Guards, AuthService } from 'frontend-toolkit';
import { Loader } from '@teamsnap/teamsnap-ui';
import Toolbar from 'organization-frontend/dist/src/hub/toolbar';

// We have to tsignore these because they dont provide type definitions.
// @ts-ignore - bleh
// import SeasonNav from 'season-frontend/build/hub/hub-nav';
// @ts-ignore - bleh
import SeasonConfigurator from 'season-frontend/build/hub/app-configurator';
// @ts-ignore - bleh
// import LeagueRostering from 'season-frontend/build/routes/leagueRostering/containers/LeagueRosterContainer';
// @ts-ignore - bleh
// import SeasonAppSDK from 'season-frontend/build/routes/appSdk/AppSdkContainer';

import { AuthWrapper } from 'auth/dist/components';
import { AuthContext } from 'auth/dist/contexts';
import ClassicIframePage from './classic/iframe';

import { CLASSIC_URL, COGSWORTH_URL, AUTH_URL, OAUTH_CLIENT_ID } from './config';

// @ts-ignore - YEAH I KNOW.
window.TeamSnap.apiv3Url = CLASSIC_URL;

function AdminGuardedRoute(user: any) {
  return function({ component, ...rest }: { name: string; path: string; component: any }) {
    // Weird JSX trick to prevent JSX Linter errors
    const Component = component;
    return (
      <Route
        {...rest}
        render={props =>
          AuthService.checkGuard(Guards.AdminOrGhostInspector, user) ? <Component {...props} /> : <PageNotFound />
        }
      />
    );
  };
}

const App = withRouter(({ history }) => {
  const context = React.useContext(AuthContext);
  const AdminRoute = AdminGuardedRoute(context.user);

  return (
    <div
      className="u-flex"
      style={{
        minHeight: '100%',
      }}
    >
      <Switch>
        {/* <Route path="/season/:divisionid/*" component={withHistory(SeasonNav)} /> */}
        {/* <Route path="/season/*" component={withHistory(SeasonNav)} /> */}
        <Route path="/organizations/:orgId">
          <OrgNav/>
        </Route>
        <Route path="/organizations">
          <OrgNav />
        </Route>
        <Route path="/accept" exact>
          <OrgNav />
        </Route>
        <Route path="/" exact>
          <OrgNav />
        </Route>
      </Switch>
      <main className="u-sizeFill u-flex u-flexJustifyCenter">
        <div className="hub-full-width">
          <Toolbar Link={Link} />
          <Switch>
            <Route exact path="/employee" render={() => <Redirect to="/employee/organizations" />} />
            <AdminRoute
              name="employee-organizations"
              path="/employee/organizations"
              component={EmployeeOrganizations}
            />
            <Route exact path="/organizations/:orgId/programs" component={ProgramsPage} />
            <Route exact path="/organizations/:orgId/programs/new" component={ProgramNew} />
            <Route exact path="/organizations/:orgId/programs/:programId" component={SeasonsPage} />
            <Route exact path="/organizations/:orgId/programs/:programId/edit" component={ProgramEdit} />
            <Route exact path="/organizations/:orgId/programs-report" component={ProgramReportPage} />
            <Route exact path="/organizations/:orgId/members" component={MembersPage} />
            <Route exact path="/organizations/:orgId/members/new" component={AddMemberPage} />
            <Route exact path="/organizations/:orgId/members/:memberId" component={MemberPage} />
            <Route exact path="/organizations/:orgId/settings" component={OrgSettingsPage as any} />
            <Route exact path="/organizations/:orgId/staff" component={StaffPage} />
            <Route
              exact
              path="/season/:divisionid/registration"
              render={({ match, location, history }) => {
                return <ClassicIframePage classicDivisionUrl="registration" divisionId={+match.params.divisionid} />;
              }}
            />
            {/* <Route exact path="/season/:divisionid/*">
                  <SeasonAppSDK params={{ divisionid: 8 }}>
                    <Switch>
                      <Route exact path="/season/:divisionid/rostering" component={LeagueRostering} />
                    </Switch>
                  </SeasonAppSDK>
                </Route> */}
            <Route exact path="/users">
              {/* <Users /> */}
            </Route>
            <Route exact path="/">
              {/* <Home /> */}
            </Route>
            <Redirect from="/organizations/:orgId" to="/organizations/:orgId/programs" />
          </Switch>
        </div>
      </main>
    </div>
  );
});

const Wrapper = () => (
  <Router>
    <AuthWrapper
      clientId={OAUTH_CLIENT_ID}
      cogsworthUrl={COGSWORTH_URL}
      authEndpoint={AUTH_URL}
      loadingIndicator={<Loader type="spin" />}
    >
      <App />
    </AuthWrapper>
  </Router>
);

const configurators = [OrgConfigurator, SeasonConfigurator];

export default configurators.reduce((app, configurator) => configurator(app), Wrapper);
