"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.startAppcues = exports.setupAppcues = void 0;

// setup instructions
// https://my.appcues.com/install/spa/2343
const setupAppcues = () => {
  if (typeof window.Appcues === 'undefined') {
    // eslint-disable-next-line
    console.error('Appcues is expected to be installed.');
  }
};

exports.setupAppcues = setupAppcues;

const startAppcues = () => {
  if (typeof window.Appcues !== 'undefined') {
    window.Appcues.start();
  }
};

exports.startAppcues = startAppcues;