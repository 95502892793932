"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LOAD_COLLECTIONS_ERROR = exports.LOAD_COLLECTIONS_SUCCESS = exports.LOAD_COLLECTIONS_START = exports.SET_AUTH_TOKEN = exports.SET_SNAPI_URL = exports.SET_WEPAY_STATUS = exports.HIDE_FEEDBACK = exports.SHOW_FEEDBACK = exports.HIDE_MODAL = exports.SHOW_MODAL = exports.SET_LOADED_DIVISION_TEAM_NAMES = exports.SET_LOADED_DIVISION_TEAMS = exports.SET_LOADED_DIVISIONS = exports.SET_ACTIVE_DIVISON_ID = exports.ADD_ROLLOUTS = exports.SET_LOGGED_IN_MEMBER_ID = exports.SET_STARTUP_MESSAGE = exports.SET_APP_NOTICE = exports.SET_APP_ERROR = exports.LOADING_DIVISON_ERROR = exports.LOADING_DIVISON_SUCCESS = exports.LOADING_DIVISON_START = exports.LOADING_APP_ERROR = exports.LOADING_APP_SUCCESS = exports.LOADING_APP_START = void 0;
const LOADING_APP_START = 'app.LOADING_APP_START';
exports.LOADING_APP_START = LOADING_APP_START;
const LOADING_APP_SUCCESS = 'app.LOADING_APP_SUCCESS';
exports.LOADING_APP_SUCCESS = LOADING_APP_SUCCESS;
const LOADING_APP_ERROR = 'app.LOADING_APP_ERROR';
exports.LOADING_APP_ERROR = LOADING_APP_ERROR;
const LOADING_DIVISON_START = 'app.LOADING_DIVISON_START';
exports.LOADING_DIVISON_START = LOADING_DIVISON_START;
const LOADING_DIVISON_SUCCESS = 'app.LOADING_DIVISON_SUCCESS';
exports.LOADING_DIVISON_SUCCESS = LOADING_DIVISON_SUCCESS;
const LOADING_DIVISON_ERROR = 'app.LOADING_DIVISON_ERROR';
exports.LOADING_DIVISON_ERROR = LOADING_DIVISON_ERROR;
const SET_APP_ERROR = 'app.SET_APP_ERROR';
exports.SET_APP_ERROR = SET_APP_ERROR;
const SET_APP_NOTICE = 'app.SET_APP_NOTICE';
exports.SET_APP_NOTICE = SET_APP_NOTICE;
const SET_STARTUP_MESSAGE = 'app.SET_STARTUP_MESSAGE';
exports.SET_STARTUP_MESSAGE = SET_STARTUP_MESSAGE;
const SET_LOGGED_IN_MEMBER_ID = 'app.SET_LOGGED_IN_MEMBER_ID';
exports.SET_LOGGED_IN_MEMBER_ID = SET_LOGGED_IN_MEMBER_ID;
const ADD_ROLLOUTS = 'app.ADD_ROLLOUTS';
exports.ADD_ROLLOUTS = ADD_ROLLOUTS;
const SET_ACTIVE_DIVISON_ID = 'app.SET_ACTIVE_DIVISON_ID';
exports.SET_ACTIVE_DIVISON_ID = SET_ACTIVE_DIVISON_ID;
const SET_LOADED_DIVISIONS = 'app.SET_LOADED_DIVISIONS';
exports.SET_LOADED_DIVISIONS = SET_LOADED_DIVISIONS;
const SET_LOADED_DIVISION_TEAMS = 'app.SET_LOADED_DIVISION_TEAMS';
exports.SET_LOADED_DIVISION_TEAMS = SET_LOADED_DIVISION_TEAMS;
const SET_LOADED_DIVISION_TEAM_NAMES = 'app.SET_LOADED_DIVISION_TEAM_NAMES';
exports.SET_LOADED_DIVISION_TEAM_NAMES = SET_LOADED_DIVISION_TEAM_NAMES;
const SHOW_MODAL = 'app.SHOW_MODAL';
exports.SHOW_MODAL = SHOW_MODAL;
const HIDE_MODAL = 'app.HIDE_MODAL';
exports.HIDE_MODAL = HIDE_MODAL;
const SHOW_FEEDBACK = 'app.SHOW_FEEDBACK';
exports.SHOW_FEEDBACK = SHOW_FEEDBACK;
const HIDE_FEEDBACK = 'app.HIDE_FEEDBACK';
exports.HIDE_FEEDBACK = HIDE_FEEDBACK;
const SET_WEPAY_STATUS = 'app.SET_WEPAY_STATUS';
exports.SET_WEPAY_STATUS = SET_WEPAY_STATUS;
const SET_SNAPI_URL = 'teamsnapSdk.SET_SNAPI_URL';
exports.SET_SNAPI_URL = SET_SNAPI_URL;
const SET_AUTH_TOKEN = 'teamsnapSdk.SET_AUTH_TOKEN';
exports.SET_AUTH_TOKEN = SET_AUTH_TOKEN;
const LOAD_COLLECTIONS_START = 'teamsnapSdk.LOAD_COLLECTIONS_START';
exports.LOAD_COLLECTIONS_START = LOAD_COLLECTIONS_START;
const LOAD_COLLECTIONS_SUCCESS = 'teamsnapSdk.LOAD_COLLECTIONS_SUCCESS';
exports.LOAD_COLLECTIONS_SUCCESS = LOAD_COLLECTIONS_SUCCESS;
const LOAD_COLLECTIONS_ERROR = 'teamsnapSdk.LOAD_COLLECTIONS_ERROR';
exports.LOAD_COLLECTIONS_ERROR = LOAD_COLLECTIONS_ERROR;