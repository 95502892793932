"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FORM_UPDATE_FIELD = exports.ADD_FORM_ERROR = exports.ADD_FORM = void 0;
const ADD_FORM = 'ADD_FORM';
exports.ADD_FORM = ADD_FORM;
const ADD_FORM_ERROR = 'ADD_FORM_ERROR';
exports.ADD_FORM_ERROR = ADD_FORM_ERROR;
const FORM_UPDATE_FIELD = 'FORM_UPDATE_FIELD';
exports.FORM_UPDATE_FIELD = FORM_UPDATE_FIELD;