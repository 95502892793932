"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChurnZeroService = void 0;
var ChurnZeroService = /** @class */ (function () {
    function ChurnZeroService() {
    }
    ChurnZeroService.trackChurnzeroEvent = function (eventName) {
        if (!ChurnZeroService.churnZeroLoaded()) {
            return null;
        }
        return window.ChurnZero.push(['trackEvent', eventName]);
    };
    ChurnZeroService.churnZeroLoaded = function () { return typeof window.ChurnZero !== 'undefined'; };
    return ChurnZeroService;
}());
exports.ChurnZeroService = ChurnZeroService;
