"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _actions = require("../state/analyticEvent/actions");

var _actions2 = require("../state/locationChange/actions");

const gtmMiddleware = () => next => action => {
  window.dataLayer = window.dataLayer || [];

  if ((action === null || action === void 0 ? void 0 : action.type) === _actions.ANALYTIC_EVENT) {
    const {
      url,
      eventCategory,
      eventAction,
      eventLabel
    } = action.payload;
    window.dataLayer.push({
      event: 'League JS App Event',
      url,
      eventCategory,
      eventAction,
      eventLabel
    });
  } else if ((action === null || action === void 0 ? void 0 : action.type) === _actions2.LOCATION_CHANGE && action !== null && action !== void 0 && action.payload && action !== null && action !== void 0 && action.payload.pathname) {
    window.dataLayer.push({
      event: 'virtual-page-view',
      path: action.payload.pathname
    });
  }

  return next(action);
};

var _default = gtmMiddleware;
exports.default = _default;