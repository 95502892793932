"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.convertToCollection = exports.convertFromCollection = exports.objectKeysToCamel = void 0;

var _mapKeys2 = _interopRequireDefault(require("lodash/mapKeys"));

var _parseInt2 = _interopRequireDefault(require("lodash/parseInt"));

var _utils = require("../utils");

var _dateService = require("../date-service");

/**
 * Collection JSON
 *
 * A small parser to flatten the items array of objects into a more usuable structure
 *
 */
// Convert keys to camelCase
const objectKeysToCamel = (key, value) => ({
  [(0, _utils.plissken)(key)]: value
}); // Make sure all returned values from the api are of the correct 'type'


exports.objectKeysToCamel = objectKeysToCamel;

const convertValueType = (value, type) => {
  if (value !== undefined && value !== null) {
    if (type === 'String') {
      return String(value);
    }

    if (type === 'Boolean') {
      return String(value) === 'true';
    }

    if (type === 'Integer') {
      return (0, _parseInt2.default)(value);
    }

    if (type === 'DateTime') {
      return (0, _dateService.getDate)(value);
    }
  }

  return value;
}; // Assign array colletion to mapped object


const assignCollection = (collection, key, value) => Object.assign(...collection.map(data => objectKeysToCamel(data[key], convertValueType(data[value], data.type)))); // Updated item collection from collectionJSON


const collectionItem = item => ({
  href: item.href,
  rel: item.rel,
  links: item.links && item.links.length > 0 ? assignCollection(item.links, 'rel', 'href') : [],
  ...assignCollection(item.data, 'name', 'value')
}); // convert all keys of an object to camel case


const keysToCamel = object => {
  if (object) {
    const newObject = {};
    Object.keys(object).forEach(key => {
      newObject[(0, _utils.plissken)(key)] = object[key];
    });
    return newObject;
  }

  return null;
}; // Convert CollectionJSON to JSON


const convertFromCollection = ({
  collection = {}
}) => {
  const newCollection = keysToCamel(collection);
  return { ...newCollection,
    pageInfo: keysToCamel(newCollection.pageInfo),
    links: newCollection.links && newCollection.links.length > 0 ? assignCollection(newCollection.links, 'rel', 'href') : [],
    items: newCollection.items && newCollection.items.length > 0 ? newCollection.items.map(item => collectionItem(item)) : []
  };
}; // Convert JSON to collectionJSON structur


exports.convertFromCollection = convertFromCollection;

const convertToCollection = (data, command) => {
  let payload = {}; // CMD and Items, post separate body structures

  if (command) {
    payload = (0, _mapKeys2.default)(data, (value, key) => (0, _utils.plissken)(key, true));
  } else {
    payload = Object.keys(data).map(key => ({
      name: (0, _utils.plissken)(key, true),
      value: data[key]
    }));
  }

  return command ? payload : {
    template: {
      data: payload
    }
  };
};

exports.convertToCollection = convertToCollection;