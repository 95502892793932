"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SIGNUP_STATUSES = exports.CHECKOUT_TYPES = exports.UPDATE_MEMBER_SELECTION = exports.UPDATE_HAS_UNAPPLIED_FILTERS = exports.UPDATE_CURRENT_ADVANCED_FILTER = exports.DELETE_CURRENT_ADVANCED_FILTER = exports.ADD_CURRENT_ADVANCED_FILTER = exports.UPDATE_CURRENT_FILTER = exports.TOGGLE_SHOW_TEAM_MEMBERS = exports.TOGGLE_ROSTERING_PENDING_MODE = exports.TOGGLE_ROSTER_LIST_SORT = exports.TOGGLE_ROSTER_ASSIGNMENT_VIEW = exports.TOGGLE_MEMBER_SEARCH_POPUP = exports.SET_DIVISION_ROSTER_COUNT = exports.SET_CURRENT_ROSTERING_DIVISION = exports.SET_CHANGE_TEAM_TEAM_ID = exports.SET_CHANGE_TEAM_MEMBER_ID = exports.SET_CHANGE_TEAM_DIVISION_ID = exports.SET_ASSIGNED_MEMBER_COUNT = exports.SELECT_ALL_FILTERED_MEMBERS = exports.SEARCH_FILTER_UPDATE = exports.SEARCH_FILTER_DELETE = exports.SEARCH_FILTER_CLEAR = exports.SEARCH_FILTER_ADD = exports.SEARCH_DIVISION_MEMBERS_SUCCESS = exports.SEARCH_DIVISION_MEMBERS_START = exports.SEARCH_DIVISION_MEMBERS_ERROR = exports.SAVE_FILTER = exports.ROSTER_SEARCH_END = exports.ROSTER_SEARCH_START = exports.RESET_ADVANCED_FILTER = exports.REMOVE_MEMBER_SELECTION = exports.REMOVE_ALL_MEMBER_SELECTIONS = exports.REPLACE_CURRENT_FILTER = exports.REMOVE_SAVED_FILTER = exports.LOAD_MEMBERS_FOR_TEAM_END = exports.LOAD_MEMBERS_FOR_TEAM_SUCCESS = exports.LOAD_MEMBERS_FOR_TEAM_START = exports.LOAD_LEAGUE_ROSTER_ERROR = exports.LOAD_LEAGUE_ROSTER_SUCCESS = exports.LOAD_LEAGUE_ROSTER_START = exports.LIST_CLICK_MEMBER = exports.LIST_CLICK_ALL_MEMBERS = exports.CLEAR_ASSIGNED_MEMBER_COUNT = exports.CLEAR_MEMBER_SEARCH_FILTERS = exports.CLEAR_FILTER = exports.CHANGE_TEAM_SUCCESS = exports.CHANGE_TEAM_START = exports.CHANGE_TEAM_ERROR = exports.ASSIGN_MEMBERS_TO_TEAM = exports.APPLY_ROSTER_FILTER = exports.ADD_MEMBER_SELECTION = exports.ADD_FILTERED_MEMBER_IDS = exports.ADD_STORED_FILTERS = void 0;
const ADD_STORED_FILTERS = 'leagueRostering.ADD_STORED_FILTERS';
exports.ADD_STORED_FILTERS = ADD_STORED_FILTERS;
const ADD_FILTERED_MEMBER_IDS = 'leagueRostering.ADD_FILTERED_MEMBER_IDS';
exports.ADD_FILTERED_MEMBER_IDS = ADD_FILTERED_MEMBER_IDS;
const ADD_MEMBER_SELECTION = 'leagueRostering.ADD_MEMBER_SELECTION';
exports.ADD_MEMBER_SELECTION = ADD_MEMBER_SELECTION;
const APPLY_ROSTER_FILTER = 'leagueRostering.APPLY_ROSTER_FILTER';
exports.APPLY_ROSTER_FILTER = APPLY_ROSTER_FILTER;
const ASSIGN_MEMBERS_TO_TEAM = 'leagueRostering.ASSIGN_MEMBERS_TO_TEAM';
exports.ASSIGN_MEMBERS_TO_TEAM = ASSIGN_MEMBERS_TO_TEAM;
const CHANGE_TEAM_ERROR = 'leagueRostering.CHANGE_TEAM_ERROR';
exports.CHANGE_TEAM_ERROR = CHANGE_TEAM_ERROR;
const CHANGE_TEAM_START = 'leagueRostering.CHANGE_TEAM_START';
exports.CHANGE_TEAM_START = CHANGE_TEAM_START;
const CHANGE_TEAM_SUCCESS = 'leagueRostering.CHANGE_TEAM_SUCCESS';
exports.CHANGE_TEAM_SUCCESS = CHANGE_TEAM_SUCCESS;
const CLEAR_FILTER = 'leagueRostering.CLEAR_FILTER';
exports.CLEAR_FILTER = CLEAR_FILTER;
const CLEAR_MEMBER_SEARCH_FILTERS = 'leagueRostering.CLEAR_MEMBER_SEARCH_FILTERS';
exports.CLEAR_MEMBER_SEARCH_FILTERS = CLEAR_MEMBER_SEARCH_FILTERS;
const CLEAR_ASSIGNED_MEMBER_COUNT = 'leagueRostering.CLEAR_ASSIGNED_MEMBER_COUNT';
exports.CLEAR_ASSIGNED_MEMBER_COUNT = CLEAR_ASSIGNED_MEMBER_COUNT;
const LIST_CLICK_ALL_MEMBERS = 'leagueRostering.LIST_CLICK_ALL_MEMBERS';
exports.LIST_CLICK_ALL_MEMBERS = LIST_CLICK_ALL_MEMBERS;
const LIST_CLICK_MEMBER = 'leagueRoster.LIST_CLICK_MEMBER';
exports.LIST_CLICK_MEMBER = LIST_CLICK_MEMBER;
const LOAD_LEAGUE_ROSTER_START = 'leagueRostering.LOAD_LEAGUE_ROSTER_START';
exports.LOAD_LEAGUE_ROSTER_START = LOAD_LEAGUE_ROSTER_START;
const LOAD_LEAGUE_ROSTER_SUCCESS = 'leagueRostering.LOAD_LEAGUE_ROSTER_SUCCESS';
exports.LOAD_LEAGUE_ROSTER_SUCCESS = LOAD_LEAGUE_ROSTER_SUCCESS;
const LOAD_LEAGUE_ROSTER_ERROR = 'leagueRostering.LOAD_LEAGUE_ROSTER_ERROR';
exports.LOAD_LEAGUE_ROSTER_ERROR = LOAD_LEAGUE_ROSTER_ERROR;
const LOAD_MEMBERS_FOR_TEAM_START = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_START';
exports.LOAD_MEMBERS_FOR_TEAM_START = LOAD_MEMBERS_FOR_TEAM_START;
const LOAD_MEMBERS_FOR_TEAM_SUCCESS = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_SUCCESS';
exports.LOAD_MEMBERS_FOR_TEAM_SUCCESS = LOAD_MEMBERS_FOR_TEAM_SUCCESS;
const LOAD_MEMBERS_FOR_TEAM_END = 'leagueRostering.LOAD_MEMBERS_FOR_TEAM_END';
exports.LOAD_MEMBERS_FOR_TEAM_END = LOAD_MEMBERS_FOR_TEAM_END;
const REMOVE_SAVED_FILTER = 'leagueRostering.REMOVE_SAVED_FILTER';
exports.REMOVE_SAVED_FILTER = REMOVE_SAVED_FILTER;
const REPLACE_CURRENT_FILTER = 'leagueRostering.REPLACE_CURRENT_FILTER';
exports.REPLACE_CURRENT_FILTER = REPLACE_CURRENT_FILTER;
const REMOVE_ALL_MEMBER_SELECTIONS = 'leagueRostering.REMOVE_ALL_MEMBER_SELECTIONS';
exports.REMOVE_ALL_MEMBER_SELECTIONS = REMOVE_ALL_MEMBER_SELECTIONS;
const REMOVE_MEMBER_SELECTION = 'leagueRostering.REMOVE_MEMBER_SELECTION';
exports.REMOVE_MEMBER_SELECTION = REMOVE_MEMBER_SELECTION;
const RESET_ADVANCED_FILTER = 'leagueRostering.RESET_ADVANCED_FILTER';
exports.RESET_ADVANCED_FILTER = RESET_ADVANCED_FILTER;
const ROSTER_SEARCH_START = 'leagueRostering.ROSTER_SEARCH_START';
exports.ROSTER_SEARCH_START = ROSTER_SEARCH_START;
const ROSTER_SEARCH_END = 'leagueRostering.ROSTER_SEARCH_END';
exports.ROSTER_SEARCH_END = ROSTER_SEARCH_END;
const SAVE_FILTER = 'leagueRostering.SAVE_FILTER';
exports.SAVE_FILTER = SAVE_FILTER;
const SEARCH_DIVISION_MEMBERS_ERROR = 'leagueRoster.SEARCH_DIVISION_MEMBERS_ERROR';
exports.SEARCH_DIVISION_MEMBERS_ERROR = SEARCH_DIVISION_MEMBERS_ERROR;
const SEARCH_DIVISION_MEMBERS_START = 'leagueRoster.SEARCH_DIVISION_MEMBERS_START';
exports.SEARCH_DIVISION_MEMBERS_START = SEARCH_DIVISION_MEMBERS_START;
const SEARCH_DIVISION_MEMBERS_SUCCESS = 'leagueRoster.SEARCH_DIVISION_MEMBERS_SUCCESS';
exports.SEARCH_DIVISION_MEMBERS_SUCCESS = SEARCH_DIVISION_MEMBERS_SUCCESS;
const SEARCH_FILTER_ADD = 'leagueRoster.SEARCH_FILTER_ADD';
exports.SEARCH_FILTER_ADD = SEARCH_FILTER_ADD;
const SEARCH_FILTER_CLEAR = 'leagueRoster.SEARCH_FILTER_CLEAR';
exports.SEARCH_FILTER_CLEAR = SEARCH_FILTER_CLEAR;
const SEARCH_FILTER_DELETE = 'leagueRoster.SEARCH_FILTER_DELETE';
exports.SEARCH_FILTER_DELETE = SEARCH_FILTER_DELETE;
const SEARCH_FILTER_UPDATE = 'leagueRoster.SEARCH_FILTER_UPDATE';
exports.SEARCH_FILTER_UPDATE = SEARCH_FILTER_UPDATE;
const SELECT_ALL_FILTERED_MEMBERS = 'leagueRostering.SELECT_ALL_FILTERED_MEMBERS';
exports.SELECT_ALL_FILTERED_MEMBERS = SELECT_ALL_FILTERED_MEMBERS;
const SET_ASSIGNED_MEMBER_COUNT = 'leagueRostering.SET_ASSIGNED_MEMBER_COUNT';
exports.SET_ASSIGNED_MEMBER_COUNT = SET_ASSIGNED_MEMBER_COUNT;
const SET_CHANGE_TEAM_DIVISION_ID = 'leagueRostering.SET_CHANGE_TEAM_DIVISION_ID';
exports.SET_CHANGE_TEAM_DIVISION_ID = SET_CHANGE_TEAM_DIVISION_ID;
const SET_CHANGE_TEAM_MEMBER_ID = 'leagueRostering.SET_CHANGE_TEAM_MEMBER_ID';
exports.SET_CHANGE_TEAM_MEMBER_ID = SET_CHANGE_TEAM_MEMBER_ID;
const SET_CHANGE_TEAM_TEAM_ID = 'leagueRostering.SET_CHANGE_TEAM_TEAM_ID';
exports.SET_CHANGE_TEAM_TEAM_ID = SET_CHANGE_TEAM_TEAM_ID;
const SET_CURRENT_ROSTERING_DIVISION = 'leagueRostering.SET_CURRENT_ROSTERING_DIVISION';
exports.SET_CURRENT_ROSTERING_DIVISION = SET_CURRENT_ROSTERING_DIVISION;
const SET_DIVISION_ROSTER_COUNT = 'leagueRostering.SET_DIVISION_ROSTER_COUNT';
exports.SET_DIVISION_ROSTER_COUNT = SET_DIVISION_ROSTER_COUNT;
const TOGGLE_MEMBER_SEARCH_POPUP = 'leagueRostering.TOGGLE_MEMBER_SEARCH_POPUP';
exports.TOGGLE_MEMBER_SEARCH_POPUP = TOGGLE_MEMBER_SEARCH_POPUP;
const TOGGLE_ROSTER_ASSIGNMENT_VIEW = 'leagueRostering.TOGGLE_ROSTER_ASSIGNMENT_VIEW';
exports.TOGGLE_ROSTER_ASSIGNMENT_VIEW = TOGGLE_ROSTER_ASSIGNMENT_VIEW;
const TOGGLE_ROSTER_LIST_SORT = 'leagueRostering.TOGGLE_ROSTER_LIST_SORT';
exports.TOGGLE_ROSTER_LIST_SORT = TOGGLE_ROSTER_LIST_SORT;
const TOGGLE_ROSTERING_PENDING_MODE = 'leagueRostering.TOGGLE_ROSTERING_PENDING_MODE';
exports.TOGGLE_ROSTERING_PENDING_MODE = TOGGLE_ROSTERING_PENDING_MODE;
const TOGGLE_SHOW_TEAM_MEMBERS = 'leagueRostering.TOGGLE_SHOW_TEAM_MEMBERS';
exports.TOGGLE_SHOW_TEAM_MEMBERS = TOGGLE_SHOW_TEAM_MEMBERS;
const UPDATE_CURRENT_FILTER = 'leagueRostering.UPDATE_CURRENT_FILTER';
exports.UPDATE_CURRENT_FILTER = UPDATE_CURRENT_FILTER;
const ADD_CURRENT_ADVANCED_FILTER = 'leagueRostering.ADD_CURRENT_ADVANCED_FILTER';
exports.ADD_CURRENT_ADVANCED_FILTER = ADD_CURRENT_ADVANCED_FILTER;
const DELETE_CURRENT_ADVANCED_FILTER = 'leagueRostering.DELETE_CURRENT_ADVANCED_FILTER';
exports.DELETE_CURRENT_ADVANCED_FILTER = DELETE_CURRENT_ADVANCED_FILTER;
const UPDATE_CURRENT_ADVANCED_FILTER = 'leagueRostering.UPDATE_CURRENT_ADVANCED_FILTER';
exports.UPDATE_CURRENT_ADVANCED_FILTER = UPDATE_CURRENT_ADVANCED_FILTER;
const UPDATE_HAS_UNAPPLIED_FILTERS = 'leagueRostering.UPDATE_HAS_UNAPPLIED_FILTERS';
exports.UPDATE_HAS_UNAPPLIED_FILTERS = UPDATE_HAS_UNAPPLIED_FILTERS;
const UPDATE_MEMBER_SELECTION = 'leagueRostering.UPDATE_MEMBER_SELECTION';
exports.UPDATE_MEMBER_SELECTION = UPDATE_MEMBER_SELECTION;
const CHECKOUT_TYPES = [{
  label: 'Installment Plan',
  value: 3
}, {
  label: 'No Fees',
  value: 5
}, {
  label: 'Offline',
  value: 4
}, {
  label: 'Pay Now',
  value: 2
}];
exports.CHECKOUT_TYPES = CHECKOUT_TYPES;
const SIGNUP_STATUSES = [{
  label: 'Authorized',
  value: 'authorized'
}, {
  label: 'Canceled',
  value: 'canceled'
}, {
  label: 'Charged Back',
  value: 'charged_back'
}, {
  label: 'Failed',
  value: 'failed'
}, {
  label: 'Paid',
  value: 'paid'
}, {
  label: 'Refunded',
  value: 'refunded'
}];
exports.SIGNUP_STATUSES = SIGNUP_STATUSES;