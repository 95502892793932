"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.rosterReducer = void 0;

var _redux = require("redux");

var _initialState = _interopRequireDefault(require("../initialState"));

var _without2 = _interopRequireDefault(require("lodash/without"));

var _parseInt2 = _interopRequireDefault(require("lodash/parseInt"));

var constants = _interopRequireWildcard(require("./constants"));

const data = (state = _initialState.default.leagueRostering.data, action) => {
  const {
    filterIndex,
    filterParams
  } = action;

  switch (action.type) {
    case constants.ADD_FILTERED_MEMBER_IDS:
      return { ...state,
        filteredMemberIds: action.memberIds,
        selectedMemberIds: [],
        selectedAllMembers: false
      };

    case constants.ADD_MEMBER_SELECTION:
      {
        const isAllSelected = action.isAllSelected !== undefined ? action.isAllSelected : state.filteredMemberIds.length && state.filteredMemberIds.length === state.selectedMemberIds.length + 1;
        return { ...state,
          selectedMemberIds: [...state.selectedMemberIds, (0, _parseInt2.default)(action.memberId)],
          selectedAllMembers: isAllSelected
        };
      }

    case constants.ADD_STORED_FILTERS:
      return { ...state,
        filters: { ...action.filters
        }
      };

    case constants.CLEAR_FILTER:
      return { ...state,
        activeSavedFilterId: null,
        currentFilter: {}
      };

    case constants.CLEAR_MEMBER_SEARCH_FILTERS:
      {
        let currentFilterCopy = { ...state.currentFilter
        };

        switch (action.name) {
          case 'divisionOrTeam':
            delete currentFilterCopy.divisionId;
            delete currentFilterCopy.teamId;
            break;

          case 'memberStatus':
            delete currentFilterCopy.hideRostered;
            delete currentFilterCopy.pending_assignments;
            break;

          case 'memberInfo':
            delete currentFilterCopy.born_after_date;
            delete currentFilterCopy.born_before_date;
            delete currentFilterCopy.first_name;
            delete currentFilterCopy.gender;
            delete currentFilterCopy.last_name;
            break;

          case 'registrationStatus':
            delete currentFilterCopy.registration_form_id;
            delete currentFilterCopy.registration_form_line_item_id;
            delete currentFilterCopy.registration_form_line_item_option_id;
            break;

          case 'customFields':
            {
              delete currentFilterCopy.advancedFilters;
              currentFilterCopy.advancedFilters = {};
              break;
            }

          default:
            currentFilterCopy = {};
        }

        return { ...state,
          activeSavedFilterId: null,
          currentFilter: { ...currentFilterCopy
          }
        };
      }

    case constants.SET_ASSIGNED_MEMBER_COUNT:
      if (action.teamId) {
        return { ...state,
          countAssignedToTeam: { ...state.countAssignedToTeam,
            [action.teamId]: action.count
          }
        };
      }

      return { ...state,
        countAssignedToDivision: { ...state.countAssignedToDivision,
          [action.divisionId]: action.count
        }
      };

    case constants.LOAD_MEMBERS_FOR_TEAM_START:
      return { ...state,
        loadingMembersForTeamId: { ...state.loadingMembersForTeamId,
          [action.teamId]: true
        }
      };

    case constants.LOAD_MEMBERS_FOR_TEAM_SUCCESS:
      return { ...state,
        loadedMembersForTeamId: [...state.loadedMembersForTeamId, action.teamId],
        loadingMembersForTeamId: { ...state.loadingMembersForTeamId,
          [action.teamId]: false
        }
      };

    case constants.LOAD_MEMBERS_FOR_TEAM_END:
      return { ...state,
        loadingMembersForTeamId: { ...state.loadingMembersForTeamId,
          [action.teamId]: false
        }
      };

    case constants.REMOVE_ALL_MEMBER_SELECTIONS:
      return { ...state,
        selectedMemberIds: [],
        selectedAllMembers: false
      };

    case constants.REMOVE_MEMBER_SELECTION:
      {
        const index = state.selectedMemberIds.indexOf(action.memberId);
        const newArray = [...state.selectedMemberIds];
        newArray.splice(index, 1);
        return { ...state,
          selectedMemberIds: [...newArray],
          selectedAllMembers: false
        };
      }

    case constants.REMOVE_SAVED_FILTER:
      {
        const newFilters = { ...state.filters
        };
        delete newFilters[action.filterIndex];
        return { ...state,
          filters: { ...newFilters
          }
        };
      }

    case constants.REPLACE_CURRENT_FILTER:
      return { ...state,
        currentFilter: { ...filterParams,
          search_title: ''
        },
        activeSavedFilterId: action.filterIndex
      };

    case constants.ROSTER_SEARCH_START:
      return { ...state,
        isSearching: true
      };

    case constants.ROSTER_SEARCH_END:
      return { ...state,
        isSearching: false
      };

    case constants.RESET_ADVANCED_FILTER:
      return { ...state,
        currentFilter: { ...state.currentFilter,
          advancedFilters: { ...state.currentFilter.advancedFilters,
            [filterIndex]: {
              field: null,
              operator: null,
              value: null
            }
          }
        }
      };

    case constants.SELECT_ALL_FILTERED_MEMBERS:
      return { ...state,
        selectedMemberIds: [...state.filteredMemberIds],
        selectedAllMembers: true
      };

    case constants.SAVE_FILTER:
      {
        const filters = { ...(state.filters || {}),
          [filterIndex]: filterParams
        };
        return { ...state,
          filters,
          currentFilter: { ...state.currentFilter,
            search_title: ''
          }
        };
      }

    case constants.UPDATE_CURRENT_FILTER:
      {
        const newState = { ...state,
          activeSavedFilterId: null,
          currentFilter: { ...state.currentFilter,
            [filterParams.fieldName]: filterParams.value
          }
        };
        return { ...newState
        };
      }

    case constants.UPDATE_MEMBER_SELECTION:
      return { ...state,
        selectedMemberIds: [...action.selectedMemberIds],
        selectedAllMembers: action.selectedAllMembers
      };

    case constants.ADD_CURRENT_ADVANCED_FILTER:
      {
        const field = filterParams.field || null;
        const operator = filterParams.operator || null;
        const value = filterParams.value || null;
        return { ...state,
          currentFilter: { ...state.currentFilter,
            advancedFilters: { ...state.currentFilter.advancedFilters,
              [filterIndex]: {
                field,
                operator,
                value
              }
            }
          },
          activeSavedFilterId: null
        };
      }

    case constants.DELETE_CURRENT_ADVANCED_FILTER:
      {
        const advancedFilters = { ...state.currentFilter.advancedFilters
        };
        delete advancedFilters[filterIndex];
        return { ...state,
          currentFilter: { ...state.currentFilter,
            advancedFilters
          },
          activeSavedFilterId: null
        };
      }

    case constants.UPDATE_CURRENT_ADVANCED_FILTER:
      return { ...state,
        currentFilter: { ...state.currentFilter,
          advancedFilters: { ...state.currentFilter.advancedFilters,
            [filterIndex]: { ...state.currentFilter.advancedFilters[filterIndex],
              [filterParams.fieldName]: filterParams.value
            }
          }
        },
        activeSavedFilterId: null
      };

    case constants.LIST_CLICK_ALL_MEMBERS:
      return { ...state,
        selectedMemberIds: action.checkedMemberIds,
        selectedAllMembers: action.checkedAllMembers
      };

    case constants.SET_DIVISION_ROSTER_COUNT:
      return { ...state,
        divisionRosterCount: { ...state.divisionRosterCount,
          [action.divisionId]: action.totalItems
        }
      };

    case constants.SET_CURRENT_ROSTERING_DIVISION:
      return { ...state,
        currentDivisionId: action.divisionId
      };

    case constants.TOGGLE_MEMBER_SEARCH_POPUP:
      return { ...state,
        openedMemberSearchPopupId: action.openedMemberSearchPopupId
      };

    case constants.TOGGLE_ROSTER_ASSIGNMENT_VIEW:
      return { ...state,
        rosterAssignmentView: action.view
      };

    case constants.TOGGLE_ROSTER_LIST_SORT:
      return { ...state,
        sortOn: action.sortOn,
        sortReverse: action.sortReverse
      };

    case constants.TOGGLE_SHOW_TEAM_MEMBERS:
      {
        if (action.setShowing) {
          return { ...state,
            showTeamMembersForTeamId: [...state.showTeamMembersForTeamId, action.teamId]
          };
        }

        const teamsToShow = [...state.showTeamMembersForTeamId];
        const teamIndex = teamsToShow.indexOf(action.teamId);

        if (teamIndex > -1) {
          teamsToShow.splice(teamIndex, 1);
          return { ...state,
            showTeamMembersForTeamId: teamsToShow
          };
        }

        return state;
      }

    case constants.TOGGLE_ROSTERING_PENDING_MODE:
      {
        return { ...state,
          pendingModeEnabled: action.pendingModeEnabled
        };
      }

    case constants.UPDATE_HAS_UNAPPLIED_FILTERS:
      {
        return { ...state,
          currentFilter: { ...state.currentFilter,
            hasUnappliedFilters: action.value
          }
        };
      }

    default:
      return state;
  }
};

const rosterReducer = (0, _redux.combineReducers)({
  data
});
exports.rosterReducer = rosterReducer;
var _default = rosterReducer;
exports.default = _default;