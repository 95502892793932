"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("teamsnap.js/lib/teamsnap");

// This is a wrapper around our teamsnap sdk since I don't want
// it to be used globally
const teamsnapSdk = () => window.teamsnap;

var _default = teamsnapSdk;
exports.default = _default;