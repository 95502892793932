"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initialState = _interopRequireDefault(require("../initialState"));

var constants = _interopRequireWildcard(require("./constants"));

function formsReducer(state = _initialState.default.forms, action) {
  const {
    formName,
    fieldName,
    fieldValue
  } = action;

  switch (action.type) {
    case constants.ADD_FORM:
      return { ...state,
        [formName]: {}
      };

    case constants.ADD_FORM_ERROR:
      return state;

    case constants.FORM_UPDATE_FIELD:
      return { ...state,
        [formName]: { ...(state[formName] || {}),
          [fieldName]: fieldValue
        }
      };

    default:
      return state;
  }
}

var _default = formsReducer;
exports.default = _default;