"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.locationChange = exports.LOCATION_CHANGE = void 0;
// This action dispatches whenever the location changes via a <Route /> It's used for:
// 1. clearing error messages (state/app/reducer)
// 2. for pushing a virtual page view into the GTM data layer (middleware/googleTagManager)
// 3. for starting Appcues (middleware/appcues)
// 4. not deleting all emails checked in league messages (leagueMessagesReducer)
const LOCATION_CHANGE = 'LOCATION_CHANGE';
exports.LOCATION_CHANGE = LOCATION_CHANGE;

const locationChange = pathname => {
  return {
    type: LOCATION_CHANGE,
    payload: {
      pathname
    }
  };
};

exports.locationChange = locationChange;