"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constants = require("../app/constants");

const initialState = {
  visible: false,
  feedbackProps: {}
};

function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case _constants.SHOW_FEEDBACK:
      return { ...state,
        feedbackProps: action.payload.feedbackProps,
        visible: true
      };

    case _constants.HIDE_FEEDBACK:
      return initialState;

    default:
      return state;
  }
}

var _default = feedbackReducer;
exports.default = _default;