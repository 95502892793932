"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _constants = require("../app/constants");

const initialState = {
  component: null,
  props: {}
};

function modalReducer(state = initialState, action) {
  switch (action.type) {
    case _constants.SHOW_MODAL:
      return { ...state,
        component: action.payload.component,
        props: action.payload.props
      };

    case _constants.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
}

var _default = modalReducer;
exports.default = _default;