"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT = exports.LIST_VIEW_SEARCH_FILTERS = exports.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = exports.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = exports.LIST_VIEW_SET_TOTAL_PAGES = exports.LIST_VIEW_SET_CURRENT_PAGE = void 0;
// ------------------------------------
// Constants
// ------------------------------------
const LIST_VIEW_SET_CURRENT_PAGE = 'leagueMembers.LIST_VIEW_SET_CURRENT_PAGE';
exports.LIST_VIEW_SET_CURRENT_PAGE = LIST_VIEW_SET_CURRENT_PAGE;
const LIST_VIEW_SET_TOTAL_PAGES = 'leagueMembers.LIST_VIEW_SET_TOTAL_PAGES';
exports.LIST_VIEW_SET_TOTAL_PAGES = LIST_VIEW_SET_TOTAL_PAGES;
const LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = 'leagueMembers.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE';
exports.LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE;
const CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = 'leagueMembers.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE';
exports.CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE = CLEAR_LIST_VIEW_PROGRAM_MEMBER_IDS_BY_PAGE;
const LIST_VIEW_SEARCH_FILTERS = 'leagueMembers.LIST_VIEW_SEARCH_FILTERS';
exports.LIST_VIEW_SEARCH_FILTERS = LIST_VIEW_SEARCH_FILTERS;
const LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT = 'leagueMembers.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT';
exports.LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT = LIST_VIEW_SET_TOTAL_PROGRAM_MEMBER_COUNT;