"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DELETE_EMAIL = exports.CLICK_DELETE_EMAIL = exports.CLICK_DELETE_ALL_EMAILS = exports.DELETE_ATTACHMENT = exports.UPDATE_ATTACHMENT_PROGRESS = exports.UPDATE_ATTACHMENT = exports.ADD_ATTACHMENT = exports.CLEAR_ATTACHMENTS = exports.SAVING_EMAIL_STATUS = exports.SET_FROM_EMAIL_ADDRESS = exports.SET_ALERT_RECIPIENT = exports.SET_RECIPIENT = exports.SET_BODY = exports.SET_SUBJECT = exports.CLEAR_BROADCAST_EMAIL = exports.SET_BROADCAST_EMAIL = exports.LOAD_VIEW_EMAIL_ERROR = exports.LOAD_VIEW_EMAIL_SUCCESS = exports.LOAD_VIEW_EMAIL_START = exports.LOAD_CLUB_EMAIL_ERROR = exports.LOAD_CLUB_EMAIL_SUCCESS = exports.LOAD_CLUB_EMAIL_START = exports.LOAD_LEAGUE_MESSAGES_ERROR = exports.LOAD_LEAGUE_MESSAGES_SUCCESS = exports.LOAD_LEAGUE_MESSAGES_START = void 0;
// ------------------------------------
// Constants
// ------------------------------------
const LOAD_LEAGUE_MESSAGES_START = 'leagueMessages.LOAD_LEAGUE_MESSAGES_START';
exports.LOAD_LEAGUE_MESSAGES_START = LOAD_LEAGUE_MESSAGES_START;
const LOAD_LEAGUE_MESSAGES_SUCCESS = 'leagueMessages.LOAD_LEAGUE_MESSAGES_SUCCESS';
exports.LOAD_LEAGUE_MESSAGES_SUCCESS = LOAD_LEAGUE_MESSAGES_SUCCESS;
const LOAD_LEAGUE_MESSAGES_ERROR = 'leagueMessages.LOAD_LEAGUE_MESSAGES_ERROR'; // ------------------------------------
// Email Constants
// ------------------------------------

exports.LOAD_LEAGUE_MESSAGES_ERROR = LOAD_LEAGUE_MESSAGES_ERROR;
const LOAD_CLUB_EMAIL_START = 'leagueMessages.LOAD_CLUB_EMAIL_START';
exports.LOAD_CLUB_EMAIL_START = LOAD_CLUB_EMAIL_START;
const LOAD_CLUB_EMAIL_SUCCESS = 'leagueMessages.LOAD_CLUB_EMAIL_SUCCESS';
exports.LOAD_CLUB_EMAIL_SUCCESS = LOAD_CLUB_EMAIL_SUCCESS;
const LOAD_CLUB_EMAIL_ERROR = 'leagueMessages.LOAD_CLUB_EMAIL_ERROR';
exports.LOAD_CLUB_EMAIL_ERROR = LOAD_CLUB_EMAIL_ERROR;
const LOAD_VIEW_EMAIL_START = 'leagueMessages.LOAD_VIEW_EMAIL_START';
exports.LOAD_VIEW_EMAIL_START = LOAD_VIEW_EMAIL_START;
const LOAD_VIEW_EMAIL_SUCCESS = 'leagueMessages.LOAD_VIEW_EMAIL_SUCCESS';
exports.LOAD_VIEW_EMAIL_SUCCESS = LOAD_VIEW_EMAIL_SUCCESS;
const LOAD_VIEW_EMAIL_ERROR = 'leagueMessages.LOAD_VIEW_EMAIL_ERROR';
exports.LOAD_VIEW_EMAIL_ERROR = LOAD_VIEW_EMAIL_ERROR;
const SET_BROADCAST_EMAIL = 'leagueMessages.SET_BROADCAST_EMAIL';
exports.SET_BROADCAST_EMAIL = SET_BROADCAST_EMAIL;
const CLEAR_BROADCAST_EMAIL = 'leagueMessages.CLEAR_BROADCAST_EMAIL';
exports.CLEAR_BROADCAST_EMAIL = CLEAR_BROADCAST_EMAIL;
const SET_SUBJECT = 'leagueMessages.SET_SUBJECT';
exports.SET_SUBJECT = SET_SUBJECT;
const SET_BODY = 'leagueMessages.SET_BODY';
exports.SET_BODY = SET_BODY;
const SET_RECIPIENT = 'leagueMessages.SET_RECIPIENT';
exports.SET_RECIPIENT = SET_RECIPIENT;
const SET_ALERT_RECIPIENT = 'leagueMessages.SET_ALERT_RECIPIENT';
exports.SET_ALERT_RECIPIENT = SET_ALERT_RECIPIENT;
const SET_FROM_EMAIL_ADDRESS = 'leagueMessages.SET_FROM_EMAIL_ADDRESS';
exports.SET_FROM_EMAIL_ADDRESS = SET_FROM_EMAIL_ADDRESS;
const SAVING_EMAIL_STATUS = 'leagueMessages.SAVING_EMAIL_STATUS';
exports.SAVING_EMAIL_STATUS = SAVING_EMAIL_STATUS;
const CLEAR_ATTACHMENTS = 'leagueMessages.CLEAR_ATTACHMENTS';
exports.CLEAR_ATTACHMENTS = CLEAR_ATTACHMENTS;
const ADD_ATTACHMENT = 'leagueMessages.ADD_ATTACHMENT';
exports.ADD_ATTACHMENT = ADD_ATTACHMENT;
const UPDATE_ATTACHMENT = 'leagueMessages.UPDATE_ATTACHMENT';
exports.UPDATE_ATTACHMENT = UPDATE_ATTACHMENT;
const UPDATE_ATTACHMENT_PROGRESS = 'leagueMessages.UPDATE_ATTACHMENT_PROGRESS';
exports.UPDATE_ATTACHMENT_PROGRESS = UPDATE_ATTACHMENT_PROGRESS;
const DELETE_ATTACHMENT = 'leagueMessages.DELETE_ATTACHMENT';
exports.DELETE_ATTACHMENT = DELETE_ATTACHMENT;
const CLICK_DELETE_ALL_EMAILS = 'leagueMessages.CLICK_DELETE_ALL_EMAILS';
exports.CLICK_DELETE_ALL_EMAILS = CLICK_DELETE_ALL_EMAILS;
const CLICK_DELETE_EMAIL = 'leagueMessages.CLICK_DELETE_EMAIL';
exports.CLICK_DELETE_EMAIL = CLICK_DELETE_EMAIL;
const DELETE_EMAIL = 'leagueMessages.DELETE_EMAIL';
exports.DELETE_EMAIL = DELETE_EMAIL;