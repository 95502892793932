"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.requestAll = exports.request = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _exceptionNotifier = require("../../middleware/exceptionNotifier");

var _collectionJson = require("./collectionJson");

/* eslint-disable no-console */

/**
 * Request
 *
 * A wrapper around axios to standardize all request calls with the same headers and handlers
 *
 * TODO:
 *  - It might be nice to make this a little more flexible, so we aren't tied to the
 *    apiv3 url and collection JSON only.  Maybe pass config params to override?
 *  - It would be good if we setup a better ENV config instead of relying on configuration
 *    from classic.
 */
// Polyfill for .finally support
// https://github.com/axios/axios/blob/master/COOKBOOK.md
require('promise.prototype.finally').shim(); // setup window variables


window.TeamSnap = window.TeamSnap || {}; // check for SSO token

const cookies = document.cookie.split(';').reduce((res, c) => {
  const [key, val] = c.trim().split('=').map(decodeURIComponent);

  try {
    return Object.assign(res, {
      [key]: JSON.parse(val)
    });
  } catch (e) {
    return Object.assign(res, {
      [key]: val
    });
  }
}, {});
const cookie = cookies.teamsnap_sso || cookies.teamsnap_sso_dev;
let headers = {
  accept: 'application/vnd.collection+json',
  authorization: `Bearer ${window.TeamSnap.authenticationToken}`
};

if (cookie) {
  console.log("REPLACING HEADERS DUE TO COOKIE");
  headers = {
    accept: 'application/vnd.collection+json'
  };
} // Setup local instance of axios with default url and headers


const axios = _axios.default.create({
  baseURL: `${window.TeamSnap.apiv3Url}`,
  headers: headers,
  withCredentials: !!cookie
}); // Add start and end times to axios request and response objects


axios.interceptors.request.use(config => ({ ...config,
  startTime: new Date().getTime()
}));
axios.interceptors.response.use(response => ({ ...response,
  config: { ...response.config,
    endTime: new Date().getTime()
  }
})); // OnSuccess fired automatically after a successful v3 response

const onSuccess = ({
  config: {
    url,
    params,
    method,
    headers,
    startTime,
    endTime
  },
  data,
  status,
  statusText
}) => {
  const collection = (0, _collectionJson.convertFromCollection)(data);
  const collectionRequest = collection.rel === 'bulk_load' ? `bulk_load [${params.types}]` : collection.rel || '';
  console.log(`Success - ${collectionRequest}`, {
    request: {
      time: `${endTime - startTime} ms`,
      url,
      params,
      method,
      headers,
      status,
      statusText
    },
    response: data,
    data: collection.items
  });
  return collection;
}; // OnError fired automatically after a failed v3 response


const onError = ({
  response: {
    config: {
      url,
      params,
      method,
      headers
    },
    status,
    statusText,
    data
  },
  message
}) => {
  const errorObject = {
    status: status || 500,
    statusText: statusText || 'Something went wrong',
    message: data.collection.error.message || message
  };
  console.error(`Failed - ${url || 'Request'}`, {
    request: {
      url,
      params,
      method,
      headers,
      status,
      statusText
    },
    response: errorObject
  });
  (0, _exceptionNotifier.notify)('APIv3', errorObject.statusText, {
    request: {
      url,
      params,
      method,
      headers,
      status,
      statusText
    },
    response: errorObject
  });
  return Promise.reject(errorObject);
}; // Main request wrapper for axios


const request = options => axios(options).then(data => onSuccess(data)).catch(onError); // Axios 'all' request wrapper


exports.request = request;

const requestAll = items => _axios.default.all(items.map(item => request(item))).then(_axios.default.spread((...args) => Object.assign(...args.map(arg => (0, _collectionJson.objectKeysToCamel)(arg.data.collection.rel, (0, _collectionJson.convertFromCollection)(arg.data))))));

exports.requestAll = requestAll;