"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.makeRootReducer = void 0;

var _redux = require("redux");

var _reduxForm = require("redux-form");

var _reducer = _interopRequireDefault(require("../state/app/reducer"));

var _reducer2 = _interopRequireDefault(require("../state/loading/reducer"));

var _reducer3 = _interopRequireDefault(require("../state/forms/reducer"));

var _reducer4 = _interopRequireDefault(require("../state/healthCheckQuestionnaire/reducer"));

var _reducer5 = _interopRequireDefault(require("../state/modal/reducer"));

var _reducer6 = _interopRequireDefault(require("../state/feedback/reducer"));

var _reducer7 = _interopRequireDefault(require("../state/teamsnap/reducer"));

var _slice = _interopRequireDefault(require("../state/snapi/slice"));

var _reducer8 = _interopRequireDefault(require("../state/leagueMembers/reducer"));

var _reducer9 = _interopRequireDefault(require("../state/leagueRostering/reducer"));

var _reducer10 = _interopRequireDefault(require("../state/leagueMessages/reducer"));

const makeRootReducer = asyncReducers => (0, _redux.combineReducers)({
  // Add sync reducers here
  app: _reducer.default,
  form: _reduxForm.reducer,
  forms: _reducer3.default,
  feedback: _reducer6.default,
  healthCheckQuestionnaire: _reducer4.default,
  loading: _reducer2.default,
  leagueMembers: _reducer8.default,
  leagueMessages: _reducer10.default,
  leagueRostering: _reducer9.default,
  modal: _reducer5.default,
  snapi: _slice.default.reducer,
  teamsnap: _reducer7.default,
  ...asyncReducers
});

exports.makeRootReducer = makeRootReducer;
var _default = makeRootReducer;
exports.default = _default;