"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  app: {
    authToken: null,
    isWePayReady: false,
    removeMessage: false,
    // remove the message on the next location change
    errorMessage: null,
    // display a top level error message
    noticeMessage: null,
    // display a top level notice message
    error: null,
    // for storing any generic error
    loggedInMemberId: null,
    loadingCollections: false,
    startupMessage: '',
    snapiUrl: null,
    rollouts: {},
    activeDivisionId: null,
    loadedDivisions: [],
    // list of division ids that have been loaded
    loadedDivisionTeams: [],
    // list of division ids that have teams loaded
    loadedDivisionTeamNames: []
  },
  leaguePayments: {},
  forms: {},
  healthCheckQuestionnaire: [],
  leagueMembers: {
    listView: {
      currentPage: 1,
      perPage: 50,
      totalPages: 1,
      programMemberIdsPerPage: {
        1: null
      },
      searchFilterParams: null
    }
  },
  leagueMessages: {
    broadcastEmail: {},
    attachments: [],
    // new attachments on the current broadcastEmail
    savingEmailMessage: null,
    // status on the saving of an email
    savingEmail: false,
    // are we currently saving an email
    listEmails: {
      perPage: 30,
      // pagination, perPage
      deleteEmailIds: {},
      // which emails have been selected by id for deletion
      deleteAllEmails: false // if the delete all emails checkbox is checked

    },
    error: null // for storing any generic error

  },
  loading: {},
  teamsnap: {},
  teamsnapSdk: {
    snapiUrl: null,
    authToken: null,
    loadingCollections: false
  },
  leagueRostering: {
    data: {
      activeSavedFilterId: null,
      bulkAction: null,
      bulkActionTeamId: null,
      bulkActionDivisionId: null,
      countAssignedToDivision: {},
      countAssignedToTeam: {},
      currentDivisionId: null,
      currentFilter: {
        advancedFilters: {
          0: {
            field: 'division_id',
            operator: 'equals',
            value: null,
            type: null
          }
        },
        is_assigned: false
      },
      defaultLoadCount: 50,
      divisionRosterCount: {},
      error: null,
      filteredMemberIds: [],
      filters: {},
      isSearching: false,
      loadedMembersForTeamId: [],
      loadingLeagueRoster: false,
      loadingMembersForTeamId: {},
      openedMemberSearchPopupId: null,
      pendingModeEnabled: false,
      rosterAssignmentView: 'team',
      selectedAllMembers: false,
      selectedMemberIds: [],
      showTeamMembersForTeamId: [],
      sortOn: 'name',
      sortReverse: false
    }
  }
};
exports.default = _default;