"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.notifyConfig = void 0;
const notifyConfig = {
  // TODO: THIS NEEDS TO BE NOT BE HARD CODED.
  dsn: 'https://478be37095804b8f95950111e014b72e@o126035.ingest.sentry.io/3004373',
  environment: process.env.NODE_ENV,
  ignoreErrors: [// Attempt to filter out third-party RTCPeerConnection error (from wepay)
  'RTCPeerConnection.createOffer', // Random plugins/extensions
  'top.GLOBALS', // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error. html
  'originalCreateNotification', 'canvas.contentDocument', 'MyApp_RemoveAllHighlights', 'http://tt.epicplay.com', "Can't find variable: ZiteReader", 'jigsaw is not defined', 'ComboSearch is not defined', 'http://loading.retry.widdit.com/', 'atomicFindClose', // Facebook borked
  'fb_xd_fragment', // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload', 'EBCallBackMessageReceived', // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage'],
  blacklistUrls: [// Ignore Wepay Scripts (third party)
  /wepay\.com/i, // Facebook flakiness
  /graph\.facebook\.com/i, // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i, // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i, /static\.woopra\.com\/js\/woopra\.js/i, // Chrome extensions
  /extensions\//i, /^chrome:\/\//i, // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i, /metrics\.itunes\.apple\.com\.edgesuite\.net\//i]
};
exports.notifyConfig = notifyConfig;