"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDateRange = exports.isValidDate = exports.getCalendarMonths = exports.getDatePartsWithFormat = exports.getDateParts = exports.getDateDifference = exports.isSameOrBeforeCurrentDate = exports.fromNowDate = exports.daysInMonth = exports.getMonthYear = exports.getDay = exports.getMonth = exports.getYear = exports.formatDate = exports.getDate = exports.formatLocalDate = exports.getLocalDate = exports.isDateValid = void 0;

var _moment = _interopRequireDefault(require("moment"));

// Looks like most of these uses are related to formatting by locale, could we save some 'space' and use
// something like this instead ->
//  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
const isDateValid = (date, format) => (0, _moment.default)(date, format, true).isValid();

exports.isDateValid = isDateValid;

const getLocalDate = date => (0, _moment.default)(date);

exports.getLocalDate = getLocalDate;

const formatLocalDate = (date, format) => (0, _moment.default)(date).format(format);

exports.formatLocalDate = formatLocalDate;

const getDate = date => _moment.default.utc(date);

exports.getDate = getDate;

const formatDate = (date, format) => _moment.default.utc(date).format(format);

exports.formatDate = formatDate;

const getYear = date => _moment.default.utc(date).year();

exports.getYear = getYear;

const getMonth = date => _moment.default.utc(date).month();

exports.getMonth = getMonth;

const getDay = date => _moment.default.utc(date).date();

exports.getDay = getDay;

const getMonthYear = (year, month) => _moment.default.utc().year(year).month(month);

exports.getMonthYear = getMonthYear;

const daysInMonth = date => _moment.default.utc(date).daysInMonth();

exports.daysInMonth = daysInMonth;

const fromNowDate = date => _moment.default.utc(date).fromNow();

exports.fromNowDate = fromNowDate;

const isSameOrBeforeCurrentDate = date => _moment.default.utc(date).isSameOrBefore();

exports.isSameOrBeforeCurrentDate = isSameOrBeforeCurrentDate;

const getDateDifference = (date, measurement, float) => _moment.default.utc().diff(date, measurement, float);

exports.getDateDifference = getDateDifference;

const getDateParts = date => {
  const dateParts = _moment.default.utc(date);

  return {
    year: dateParts.year(),
    month: dateParts.month(),
    date: dateParts.date()
  };
};

exports.getDateParts = getDateParts;

const getDatePartsWithFormat = (dateString, dateFormatter = {}, originalFormat = 'MM-DD-YYYY') => {
  const date = _moment.default.utc(dateString, originalFormat);

  const formatter = {
    year: 'YYYY',
    month: 'MM',
    day: 'DD',
    date: originalFormat,
    ...dateFormatter
  };
  return {
    date: date.format(formatter.date),
    year: date.format(formatter.year),
    month: date.format(formatter.month),
    day: date.format(formatter.day)
  };
};

exports.getDatePartsWithFormat = getDatePartsWithFormat;

const isValidDate = date => {
  return date instanceof Date && !isNaN(date) && date.getTime() !== new Date('').getTime();
};

exports.isValidDate = isValidDate;

const formatDateRange = (firstDate, secondDate) => {
  const dates = [];

  const formatter = date => {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  };

  if (firstDate != null && isValidDate(new Date(firstDate))) {
    dates.push(formatter(firstDate));
  }

  if (secondDate != null && isValidDate(new Date(secondDate))) {
    dates.push(formatter(secondDate));
  }

  return dates.join(' - ');
};

exports.formatDateRange = formatDateRange;

const getCalendarMonths = () => ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

exports.getCalendarMonths = getCalendarMonths;