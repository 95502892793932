"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateService = exports.DateFormat = void 0;
/**
 * @enum DateFormat
 * This enum provides the different formatting options supported by the `dateToString` method of DateService.
 */
var DateFormat;
(function (DateFormat) {
    DateFormat[DateFormat["YYYY_MM_DD"] = 0] = "YYYY_MM_DD";
    DateFormat[DateFormat["SHORT_DATE"] = 1] = "SHORT_DATE";
    DateFormat[DateFormat["LONG_DATE"] = 2] = "LONG_DATE";
    DateFormat[DateFormat["DATE_WITH_TIME_AND_TIMEZONE"] = 3] = "DATE_WITH_TIME_AND_TIMEZONE";
})(DateFormat = exports.DateFormat || (exports.DateFormat = {}));
/**
 * @class DateService
 * used to work with Dates, leverages INTL methods (browser only) and should be used instead of moment or time.js
 */
var DateService = /** @class */ (function () {
    function DateService() {
    }
    /**
     * @method dateToString
     * @description Formats a given date with the provided formatter.
     * @param date A date object that you wish to format.
     * @param format @enum DateFormat
     * @returns string
     */
    DateService.dateToString = function (date, format) {
        if (!date)
            return "";
        var formatter = DateService.formatters[format];
        if (!formatter) {
            return "Err: unspecified formatter.";
        }
        return formatter(date);
    };
    /**
     * @method stringToDate
     * @description Converts a string into a date.
     * @param dateString A string in date format that you need to convert to a js Date.
     * @param format @enum DateFormat
     * @returns Date
     */
    DateService.stringToDate = function (dateString, format) {
        var date = new Date(dateString);
        if (format == DateFormat.YYYY_MM_DD) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        }
        return date;
    };
    // private methods below are used connected as formatter functions via the DateFormat enum.
    // mark as private static, wire up via the `formatters` property and then add tests for dateToString
    DateService.YYYY_MM_DD = function (date) {
        // Make attributes Zero padded
        var dateArr = new Intl.DateTimeFormat('en-US', { timeZone: "UTC" }).format(date)
            .split("/")
            .map(function (n) {
            return n.length == 1 ? "0" + n : n;
        });
        // Return YYYY-MM-DD format
        return dateArr[2] + "-" + dateArr[0] + "-" + dateArr[1];
    };
    DateService.SHORT_DATE = function (date) {
        var formatOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: "UTC" };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.LONG_DATE = function (date) {
        var formatOptions = { year: 'numeric', month: 'long', day: 'numeric', timeZone: "UTC" };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.DATE_WITH_TIME_AND_TIMEZONE = function (date) {
        var formatOptions = {
            hour: 'numeric',
            minute: 'numeric',
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZoneName: "short"
        };
        return new Intl.DateTimeFormat('en-US', formatOptions).format(date);
    };
    DateService.formatters = (_a = {},
        _a[DateFormat.YYYY_MM_DD] = DateService.YYYY_MM_DD,
        _a[DateFormat.SHORT_DATE] = DateService.SHORT_DATE,
        _a[DateFormat.LONG_DATE] = DateService.LONG_DATE,
        _a[DateFormat.DATE_WITH_TIME_AND_TIMEZONE] = DateService.DATE_WITH_TIME_AND_TIMEZONE,
        _a);
    return DateService;
}());
exports.DateService = DateService;
