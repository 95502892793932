"use strict";

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.camelize = camelize;
exports.capitalize = capitalize;
exports.serializeItem = serializeItem;
exports.templateToObject = templateToObject;
exports.objectToArray = objectToArray;
exports.getClassName = getClassName;
exports.trimInput = exports.getClassNameStyles = exports.findDescendants = exports.calculatePercent = exports.stringifyArray = exports.plissken = void 0;

var _isArray2 = _interopRequireDefault(require("lodash/isArray"));

var _flatten2 = _interopRequireDefault(require("lodash/flatten"));

var _snakeCase2 = _interopRequireDefault(require("lodash/snakeCase"));

var _camelCase2 = _interopRequireDefault(require("lodash/camelCase"));

// Convert snake_case <=> camelCase with 'Snake' Plissken
const plissken = (string, snake = false) => snake ? (0, _snakeCase2.default)(string) : (0, _camelCase2.default)(string);

exports.plissken = plissken;

const stringifyArray = (array, joinBy = ', ') => array.filter(Boolean).join(joinBy);

exports.stringifyArray = stringifyArray;

const calculatePercent = (total, current) => (current / total || 0) * 100; // Find all descendants in array by matching child/parent fields


exports.calculatePercent = calculatePercent;

const findDescendants = (searchValue, array, childField = 'parentId', parentField = 'id') => {
  const descendants = [];
  const children = array.filter(arr => arr[childField] === searchValue);

  if (children.length) {
    descendants.push(...children);
    children.forEach(child => {
      const grandChildren = findDescendants(child[parentField], array, childField, parentField);

      if (grandChildren) {
        descendants.push(...grandChildren);
      }
    });
  }

  return descendants;
};

exports.findDescendants = findDescendants;

function camelize(str) {
  return str.replace(/[-_]+(\w)/g, (_, char) => char.toUpperCase());
}

function capitalize(string) {
  return string.replace(/(?:^|\s)\S/g, a => a.toUpperCase());
}

function serializeItem(item) {
  const newItem = {};
  const ref1 = item.data;

  for (let i = 0, len = ref1.length; i < len; i += 1) {
    const prop = ref1[i];
    let {
      value
    } = prop;

    if (prop.type === 'DateTime' && value) {
      value = new Date(value);
    }

    if (prop.name === 'type') {
      value = camelize(value);
    }

    newItem[camelize(prop.name)] = value;
  }

  newItem.links = item.links || [];
  return newItem;
}

function templateToObject(template) {
  const newObject = {};
  template.forEach(key => {
    newObject[camelize(key.name)] = null;
  });
  return newObject;
}

function objectToArray(object) {
  return Object.keys(object).map(key => object[key]);
}

function getClassName(className, classList = []) {
  if ((0, _isArray2.default)(className)) {
    classList.push(className);
    return (0, _flatten2.default)(classList).join(' ');
  }

  if (className) {
    classList.push(className);
  }

  return classList.join(' ');
} // Merge base global class with new class definition and add css-module style override if exists


const getClassNameStyles = (key, baseClassNames, componentClassNames, componentStyles) => {
  const classNames = { ...baseClassNames,
    ...componentClassNames
  };
  const styles = key in componentStyles ? componentStyles[key] : '';
  return `${classNames[key]} ${styles}`;
};

exports.getClassNameStyles = getClassNameStyles;

const trimInput = text => {
  if (!/[^\s]/.test(text)) {
    return text.trim();
  }

  return text;
};

exports.trimInput = trimInput;