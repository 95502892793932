import React from 'react';

import { CLASSIC_URL } from "../config";

// TODO: DONT HARD CODE
const TEAMSNAP_APP_URL = CLASSIC_URL;

const resizeIFrame = (event: any) => {
  const loc = document.location;
  if (event.origin !== loc.protocol + '//localhost:3001') {return;}
  console.log('resize', event.origin, loc.protocol + '//' + loc.host, event.data.h, event.data.w);
  const myIFrame = document.getElementById('frameWrapper');
  console.log('resize frame', myIFrame, event.data.h);
  if (myIFrame) {
    myIFrame.style.height = event.data.h + 'px';
    // myIFrame.style.width  = event.data.w + 'px';
  }
};

if (window.addEventListener) {
  window.addEventListener('message', resizeIFrame, false);
} else if ((window as any).attachEvent) {
  (window as any).attachEvent('onmessage', resizeIFrame);
}

interface Props {
  classicDivisionUrl: string;
  minHeight?: number;
  divisionId: number;
}

const ClassicIframePage: React.FunctionComponent<Props> = ({minHeight, classicDivisionUrl, divisionId}) => {
  const contentURL = `${TEAMSNAP_APP_URL}/${divisionId}/${classicDivisionUrl}?hub=true`;
  const minHeightPx = `${minHeight || 680}px`;

  return (
    <div className="container-fluid">
      <div id="frameWrapper" style={{position: 'relative', minHeight: `${minHeightPx}`, paddingTop: '35px', height: '0', overflow: 'auto'}}>
        <iframe id="classicFrame" style={{position:'absolute', top: '0', left:'0', height: '100%', width: '100%'}} src={contentURL} frameBorder="0"/>
      </div>
    </div>
  );
}

export default ClassicIframePage;
