"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthService = exports.Guards = void 0;
var GHOST_INSPECTOR_EMAILS = [
    "blue007jen+natelo@gmail.com",
    "blue007jen+jimlo@gmail.com",
    "blue007jen+larrylo@gmail.com",
    "blue007jen+waynelo@gmail.com",
    "007+furies@teamsnap.com"
];
/**
 * Enum representation of guards available to check a user against.
 * If you add a new check, you'll need to add a new guard here and below in the AuthService
 * implementation.
 */
var Guards;
(function (Guards) {
    Guards[Guards["Admin"] = 0] = "Admin";
    Guards[Guards["AdminOrGhostInspector"] = 1] = "AdminOrGhostInspector";
})(Guards = exports.Guards || (exports.Guards = {}));
/**
 * Class representing a static service for basic user authorization mechanics.
 * AuthService is built to run synchronously. If you need to add asynchronous validations, there's
 * potential for a good chunk of work adjusting the existing implementation. That being said,
 * you should be able to do the async work before calling AuthService and use the guards synchronously
 * as intended.
 *
 * This class is intended to be used as a frontend tool for blocking users from accessing certain content,
 * mostly as an alternative to feature toggles in an environment where feature toggles are not available (like Org, or TST).
 *
 * Class members should remain intentionally static.
 */
var AuthService = /** @class */ (function () {
    function AuthService() {
    }
    /**
     * checkGuard executes guard functions against a user until one succeeds or all of them fail.
     * @param guard A guard defined in the Guards enum in this file.
     * @param user An object that conforms the User interface in this file.
     *
     * If a guard function succeeds, it will exit the array of guards and return early. We only require
     * one guard to pass to return true. If you want to require multiple guards to pass, create a guard
     * that composes the existing guard functions and requires each of them to pass before returning true.
     */
    AuthService.checkGuard = function (guard, user) {
        return !!AuthService.guards[guard].find(function (fn) { return fn(user); });
    };
    AuthService.isAdmin = function (user) {
        return user && user.isAdmin;
    };
    AuthService.isGhostInspector = function (user) {
        return user &&
            user.email &&
            GHOST_INSPECTOR_EMAILS.includes(user.email.toLowerCase());
    };
    /**
     * A map of guard enum values paired with an array of "guard functions"
     * Guard functions must return a boolean.
     */
    AuthService.guards = (_a = {},
        _a[Guards.Admin] = [
            AuthService.isAdmin
        ],
        _a[Guards.AdminOrGhostInspector] = [
            AuthService.isAdmin,
            AuthService.isGhostInspector
        ],
        _a);
    return AuthService;
}());
exports.AuthService = AuthService;
