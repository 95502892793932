"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.analyticEvent = exports.ANALYTIC_EVENT = void 0;
// This action is for pushing new events into the GTM data layer.
// By creating a dedicated event we have flexibility to map this action to a prop
// or nest it within a thunk.
const ANALYTIC_EVENT = 'ANALYTIC_EVENT';
exports.ANALYTIC_EVENT = ANALYTIC_EVENT;

const analyticEvent = ({
  url,
  eventCategory,
  eventAction,
  eventLabel
}) => ({
  type: ANALYTIC_EVENT,
  payload: {
    url,
    eventCategory,
    eventAction,
    eventLabel
  }
});

exports.analyticEvent = analyticEvent;