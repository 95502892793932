import ENVIRONMENT from './environment';

export const CLASSIC_URL = {
  local: "http://localhost:3001",
  staging: "https://pod-sports-org.teamsnap.com:8443/",
  prod: "https://go.teamsnap.com",
}[ENVIRONMENT];

export const COGSWORTH_URL = {
  local: "http://localhost:3004/",
  staging: "https://pod-sports-org.teamsnap.com:8443/",
  prod: "https://auth.teamsnap.com/",
}[ENVIRONMENT];

export const AUTH_URL = {
  local: "http://localhost:5501/graphql",
  staging: "https://staging-organization-api.teamsnap.com/graphql",
  prod: "https://organization-api.teamsnap.com/graphql",
}[ENVIRONMENT];

export const OAUTH_CLIENT_ID = {
  local: "hub_uid",
  staging: "hub_uid",
  prod: "67746e2da03fe8cead89a30726c15368a95eb042441f8a6a469adcfead5479ea08fca1ae0272acbb4828c65e3a52f7a280539e7dc47f68218c11b0e41cc30636",
}[ENVIRONMENT];
