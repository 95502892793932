"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _utils = require("../utils");

var _request = require("./request");

var _collectionJson = require("./collectionJson");

/**
 * API
 *
 * A wrapper around the request object to simplify apvi3 calls
 *
 */
const paramsToSnake = (params = {}) => Object.assign(...Object.keys(params).map(key => {
  const paramKey = (0, _utils.plissken)(key.replace(/(__|\.)/i, 'zz'), true).replace('zz', '__');
  const paramValue = Array.isArray(params[key]) ? params[key].join() : params[key];
  return {
    [paramKey]: paramValue
  };
}));

const requestParams = (method, url, params, config) => ({
  method,
  url,
  params: params ? paramsToSnake(params) : null,
  ...config
});

const requestData = (method, url, data, config, command = false) => ({
  method,
  url,
  data: (0, _collectionJson.convertToCollection)(data, command),
  ...config
});

const api = {
  get(url, params, config = {}) {
    return (0, _request.request)(requestParams('get', url, params, config));
  },

  post(url, params, config = {}) {
    return (0, _request.request)(requestData('post', url, params, config));
  },

  cmd(url, params, config = {}) {
    return (0, _request.request)(requestData('post', url, params, config, true));
  },

  put(url, params, config = {}) {
    return (0, _request.request)(requestData('put', url, params, config));
  },

  patch(url, params, config = {}) {
    return (0, _request.request)(requestData('patch', url, params, config));
  },

  delete(url, params, config = {}) {
    return (0, _request.request)(requestParams('delete', url, params, config));
  },

  getAll(links) {
    const getLinks = links.map(link => requestParams('get', link.url, link.params));
    return (0, _request.requestAll)(getLinks);
  } // postAll (items) {
  //   const postItems = items.map(item => requestData('post', item.url, item.params))
  //   return requestPostAll(postItems)
  // }


};
var _default = api;
exports.default = _default;