"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _initialState = _interopRequireDefault(require("../initialState"));

var constants = _interopRequireWildcard(require("./constants"));

const healthCheckQuestionnaireReducer = (state = _initialState.default.healthCheckQuestionnaire, action) => {
  switch (action.type) {
    case constants.HEALTH_CHECK_QUESTIONNAIRE:
      return action.payload;

    default:
      return state;

    case constants.CREATE_HEALTH_CHECK_QUESTIONNAIRE_TEMPLATE_QUESTION:
      {
        const mostRecentTemplateId = Math.max(...state.map(e => +e.healthCheckQuestionnaireTemplateId));
        return [...state, action.payload].filter(e => mostRecentTemplateId === +e.healthCheckQuestionnaireTemplateId);
      }
  }
};

var _default = healthCheckQuestionnaireReducer;
exports.default = _default;