"use strict";

var _interopRequireWildcard = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("/opt/build/repo/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _actions = require("../locationChange/actions");

var _initialState = _interopRequireDefault(require("../initialState"));

var _uniq2 = _interopRequireDefault(require("lodash/uniq"));

var _parseInt2 = _interopRequireDefault(require("lodash/parseInt"));

var constants = _interopRequireWildcard(require("./constants"));

function appReducer(state = _initialState.default.app, action) {
  switch (action.type) {
    case constants.SET_SNAPI_URL:
      return { ...state,
        snapiUrl: action.snapiUrl
      };

    case constants.SET_AUTH_TOKEN:
      return { ...state,
        authToken: action.authToken
      };

    case constants.LOAD_COLLECTIONS_START:
      return { ...state,
        loadingCollections: true
      };

    case constants.LOAD_COLLECTIONS_SUCCESS:
      return { ...state,
        loadingCollections: false
      };

    case constants.LOAD_COLLECTIONS_ERROR:
      return { ...state,
        loadingCollections: false
      };

    case constants.SET_WEPAY_STATUS:
      return { ...state,
        isWePayReady: action.payload
      };

    case constants.SET_APP_ERROR:
      return { ...state,
        errorMessage: action.payload,
        removeMessage: action.temporary || false
      };

    case constants.SET_APP_NOTICE:
      return { ...state,
        noticeMessage: action.payload,
        removeMessage: action.temporary || false
      };

    case constants.SET_STARTUP_MESSAGE:
      return { ...state,
        startupMessage: action.startupMessage
      };

    case constants.ADD_ROLLOUTS:
      return { ...state,
        rollouts: action.rollouts
      };

    case _actions.LOCATION_CHANGE:
      // deletes the error and notice message on the next route change
      if (state.noticeMessage || state.errorMessage) {
        if (state.removeMessage) {
          return { ...state,
            noticeMessage: null,
            errorMessage: null,
            removeMessage: false
          };
        }

        return { ...state,
          removeMessage: true
        };
      }

      return state;

    case constants.SET_LOGGED_IN_MEMBER_ID:
      return { ...state,
        loggedInMemberId: (0, _parseInt2.default)(action.memberId)
      };

    case constants.SET_ACTIVE_DIVISON_ID:
      return { ...state,
        activeDivisionId: (0, _parseInt2.default)(action.divisionId)
      };

    case constants.SET_LOADED_DIVISIONS:
      return { ...state,
        loadedDivisions: (0, _uniq2.default)([...state.loadedDivisions, ...action.loadedDivisions])
      };

    case constants.SET_LOADED_DIVISION_TEAMS:
      return { ...state,
        loadedDivisionTeams: (0, _uniq2.default)([...state.loadedDivisionTeams, ...action.loadedDivisionTeams])
      };

    case constants.SET_LOADED_DIVISION_TEAM_NAMES:
      return { ...state,
        loadedDivisionTeamNames: (0, _uniq2.default)([...state.loadedDivisionTeamNames, ...action.loadedDivisionTeamNames])
      };

    default:
      return state;
  }
}

var _default = appReducer;
exports.default = _default;